import styles from "./Logo.module.scss";
import rinataAkhmetshina from "../../assets/images/rinataAkhmetshina.svg";
import ra from "../../assets/images/ra.svg";
import mortatgeAdviser from "../../assets/images/mortatgeAdviser.svg";
import Stack from "../stack/Stack";

const Logo = () => {
    return (
        <Stack
            className={styles.logoStack}
            direction="row"
            justify="justifyEnd"
        >
            <img
                src={rinataAkhmetshina}
                alt="Rinata Achmetshina"
                className={styles.rinata}
            />
            <Stack className={styles.rightContainer} direction="column">
                <img src={ra} alt="RA" className={styles.ra} />
                <img
                    src={mortatgeAdviser}
                    alt="MORTGAGE ADVISER"
                    className={styles.mortgageAdviser}
                />
            </Stack>
        </Stack>
    );
};

export default Logo;
