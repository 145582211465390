import Stack from "../../../../shared/ui/stack/Stack";
import styles from "./HeaderSideSlogans.module.scss";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { useTranslatedData } from "../../../../app/translation/lib/useTranslatedData";

const HeaderSideSlogans = () => {
  const { i18n } = useTranslation();
  const data = useTranslatedData();

  const sloganSlide = {
    hidden: { x: "100vw" },
    visible: (custom) => ({
      x: 0,
      transition: {
        delay: custom,
        type: "tween",
        ease: "easeOut",
        duration: 1,
      },
    }),
  };

  return (
    <Stack
      className={styles.headerSideSlogans}
      direction="column"
      align="alignEnd"
    >
      <motion.div
        className="box"
        initial="hidden"
        animate="visible"
        variants={sloganSlide}
        custom={0.5}
      >
        <Stack
          className={styles.topSloganContainer}
          justify="justifyEnd"
          lang={i18n.language}
        >
          {data.mortgageAdviser}
        </Stack>
      </motion.div>

      <motion.div
        className="box"
        initial="hidden"
        animate="visible"
        variants={sloganSlide}
        custom={1.5}
      >
        <Stack
          className={styles.bottomSloganContainer}
          justify="justifyEnd"
          lang={i18n.language}
        >
          {data.guideYou}
        </Stack>
      </motion.div>
    </Stack>
  );
};

export default HeaderSideSlogans;
