import classNames from "classnames";
import Stack from "../stack/Stack";
import styles from "./SectionTitle.module.scss";
import LazyLoading from "../lazyLoading/LazyLoading";
import { useTranslation } from "react-i18next";

const SectionTitle = ({ text, color = "primary" }) => {
  const { i18n } = useTranslation();

  return (
    <LazyLoading
      delay={500}
      height="70px"
      triggerOnce={true}
    >
      <Stack
        className={classNames(styles.sectionTitle, { [styles[color]]: color })}
      >
        <h3
          className={classNames(styles.titleText, { [styles[color]]: color })}
          lang={i18n.language}
        >
          {text}
        </h3>
      </Stack>
    </LazyLoading>
  );
};

export default SectionTitle;
