const LinkedIcon = () => (
    <svg
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M19.995 20L20 19.9992V12.6642C20 9.07583 19.2275 6.31166 15.0325 6.31166C13.0158 6.31166 11.6625 7.41833 11.11 8.46749H11.0517V6.64666H7.07416V19.9992H11.2158V13.3875C11.2158 11.6467 11.5458 9.96333 13.7017 9.96333C15.8258 9.96333 15.8575 11.95 15.8575 13.4992V20H19.995Z" />
        <path d="M0.329995 6.64749H4.47666V20H0.329995V6.64749Z" />
        <path d="M2.40167 -7.62939e-06C1.07583 -7.62939e-06 0 1.07583 0 2.40166C0 3.72749 1.07583 4.82583 2.40167 4.82583C3.7275 4.82583 4.80333 3.72749 4.80333 2.40166C4.8025 1.07583 3.72667 -7.62939e-06 2.40167 -7.62939e-06Z" />
    </svg>
);

export default LinkedIcon;
