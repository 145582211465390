import { useMutation, useQuery } from '@tanstack/react-query'
import { StatisticService } from '../../../../shared/services/statistic.service'
import { useState } from 'react'


export const useStatistic = reset => {

	const [isLoad, setIsLoad] = useState(false)

	const {
		isLoading,
		data: Statistic,
		refetch,
	} = useQuery({
		queryKey: ['Get statistic'],
		queryFn: () => StatisticService.getAll(),
		select: ({ data }) =>
			data.map(st => ({
				count: st.count,
				text: st.title,
			})),
	})

	const { mutate, error } = useMutation({
		mutationKey: ['Change count'],
		mutationFn: ({ title, count }) => {
			setIsLoad(true)
			return StatisticService.update(title, count)
		},
		onSuccess() {
			refetch()
			reset()
			setIsLoad(false)
		},
		onError(error) {
			console.log(error)
			setIsLoad(false)
		},
	})

	const onSubmit = (data, title) => {
		const count = data[title]
		mutate({ title, count })
	}

	return { isLoading, isLoad, Statistic, onSubmit, error }

}
