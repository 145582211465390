import Stack from "../../../../shared/ui/stack/Stack";
import styles from "./HeaderPersonPhoto.module.scss";
import photoPerson from "../../../../shared/assets/images/photoPerson.png";

function HeaderPersonPhoto() {
  return (
    <Stack className={styles.photoContainer} justify="justifyStart">
      <img src={photoPerson} alt="person" />
    </Stack>
  );
}

export default HeaderPersonPhoto;
