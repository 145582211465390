import classNames from 'classnames';
import Input from '../../../../shared/ui/input/Input';
import Stack from '../../../../shared/ui/stack/Stack';
import styles from './Range.module.scss';
import { useEffect, useState } from 'react';

const Range = ({
	register,
	name,
	valueInput,
	valueRange,
	onInput,
	min,
	max,
	setValueTrack, 
}) => {
	const [valueTrack, setValueTrackLocal] = useState(0);

	useEffect(() => {
		const trackValue = Math.max((valueRange - min) / (max - min), 0);
		setValueTrackLocal(trackValue);
		setValueTrack(trackValue); 
	}, [valueRange, min, max, setValueTrack]);

	const handleInput = (e) => {
		onInput(e); 
	};
	return (
		<Stack className={styles.rangeStyle}>
			<Input
				register={register}
				name={name}
				type='range'
				variant='range'
				min={min}
				max={max}
				onInput={handleInput}
				options={{
					valueAsNumber: true,
				}}
				aria-valuenow={valueInput}
				aria-valuemin={min}
				aria-valuemax={max}
			/>
			<div
				style={{ '--value': valueTrack}}
				className={styles.rangeTrack}
			></div>
			<span
				className={classNames(styles.rangeNumber, {
					[styles.rangeNumberDuration]: +valueInput <= 30,
				})}
				style={{ '--value': valueTrack }}
			>
				<p>{valueInput}</p>
			</span>
			<Stack>
				<span className={styles.rangeLabel}>{min}</span>
				<span className={styles.rangeLabel}>{max}</span>
			</Stack>
		</Stack>
	);
};

export default Range;
