import App from '../../../App';
import RefreshPassword from '../../../features/auth/ui/authorization/RefreshPassword';
import AdminPage from '../../../pages/adminPage/AdminPage';
import NotFound from '../../../pages/notFound/NotFound';

export const routes = [
	{
		path: '/',
		component: App,
	},
	{
		path: '/manage',
		component: AdminPage,
	}, 
	{
        path: '/refresh-password/:resetToken',
        component: RefreshPassword,
    },
	{
		path: '*',
		component: NotFound,
	},
];
