export const initialArray = [
	{
		text: 'experience',
		count: 7,
	},
	{
		text: 'mortgages',
		count: 635,
	},
	{
		text: 'mortgageBanker',
		count: 2,
	},
]
