import { useState } from "react";
import QuestionBlock from "../questionBlock/QuestionBlock";
import styles from "./Questionnaire.module.scss";
import ResultBlock from "../resultBlock/ResultBlock";
import Title from "../title/Title";
import Stack from "../../../../shared/ui/stack/Stack";
import { useTranslatedData } from "../../../../app/translation/lib/useTranslatedData";
import SectionTitle from "../../../../shared/ui/sectionTitle/SectionTitle";

const Questionnaire = () => {
  const data = useTranslatedData();

  const { questions, results, steps } = data.questionnaireData;

  const [step, setStep] = useState(0);
  const [resultIndex, setResultIndex] = useState(null);

  const getResultIndex = (step) => parseInt(step.split("_")[1], 10);

  const handleAnswer = (answer) => {
    const nextStep = steps[step][answer ? "yes" : "no"];
    if (typeof nextStep === "string" && nextStep.startsWith("result")) {
      setResultIndex(getResultIndex(nextStep));
    } else {
      setStep(nextStep);
    }
  };

  const handleRestart = () => {
    setStep(0);
    setResultIndex(null);
  };

  return (
    <Stack
      direction="column"
      className={styles.questionBlock}
    >
      <SectionTitle color="secondary" />
      <Title data={data} />
      {resultIndex === null ? (
        <QuestionBlock
          question={questions[step]}
          onAnswer={handleAnswer}
        />
      ) : (
        <ResultBlock
          result={results[resultIndex]}
          titleResult={data.titleResult}
          onRestart={handleRestart}
        />
      )}
    </Stack>
  );
};

export default Questionnaire;
