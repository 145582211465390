import styles from './Reviews.module.scss';
import Stack from '../../../../shared/ui/stack/Stack';
import { Slider } from '../slider/Slider';
import SectionTitle from '../../../../shared/ui/sectionTitle/SectionTitle';
import { useTranslatedData } from '../../../../app/translation/lib/useTranslatedData';
import { SliderDesktop } from '../sliderDesktop/SliderDesktop';

export const Reviews = () => {
    const data = useTranslatedData();

    return (
        <Stack
            id="clientReviews"
            justify='alignCenter'
            direction="column"
        >
            <SectionTitle text={data.clientReviews} />
            <div className={styles.slider}>
                <Slider />
            </div>
            <div className={styles.desktopSlider}>
                <SliderDesktop />
            </div>
        </Stack>
    );
}