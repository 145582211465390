 export const reviewsData = [
    {
        "path": "1.jpg",
        "alt": "Description"
    },
    {
        "path": "2.jpg",
        "alt": "Description"
    },
    {
        "path": "4.jpg",
        "alt": "Description"
    },
    {
        "path": "6.jpg",
        "alt": "Description"
    },
    {
        "path": "7.jpg",
        "alt": "Description"
    },
    {
        "path": "9.jpg",
        "alt": "Description"
    }
]