import TelegramIcon from "./componentsSocial/TelegramIcon";
import WhatsAppIcon from "./componentsSocial/WhatsAppIcon";
import PhoneIcon from "./componentsSocial/PhoneIcon";
import EmailIcon from "./componentsSocial/EmailIcon";
import InstagramIcon from "./componentsSocial/InstagramIcon";
import LinkedIcon from "./componentsSocial/LinkedIcon";

const allSocialIcons = [
  {
    name: "LinkedIn",
    component: LinkedIcon,
    link: "https://cz.linkedin.com/in/mortgage-cz",
  },
  {
    name: "Telegram",
    component: TelegramIcon,
    link: "https://t.me/mortgage_cz",
  },
  {
    name: "WhatsApp",
    component: WhatsAppIcon,
    link: "https://wa.me/420723003666",
  },
  {
    name: "Phone",
    component: PhoneIcon,
    link: "tel:+420723003666",
  },
  {
    name: "Email",
    component: EmailIcon,
    link: "mailto:mortgage@email.cz?subject=Contact&body=Hello",
  },
  {
    name: "Instagram",
    component: InstagramIcon,
    link: "https://www.instagram.com/mortgage_cz?igsh=MW00am05dXBqYWM3Yg%3D%3D&utm_source=qr",
  }
];

export default allSocialIcons;
