import { useTranslation } from "react-i18next";
import Stack from "../../../../shared/ui/stack/Stack";
import styles from "./MainGetGuide.module.scss";

const MainGetGuide = ({ data }) => {
  const { i18n } = useTranslation();

  return (
    <Stack className={styles.mainGetGuide} lang={i18n.language}>
      <img src="/images/book.svg" alt="book" />

      <Stack direction="column">
        <p className={styles.firstRow}>{data.howToGet}</p>
        <p className={styles.secondRow}>{data.aMortgage}</p>
        <p className={styles.thirdRow}>{data.tipsAndTricks}</p>
        <p className={styles.fourthRow}>{data.fromAnExBanker}</p>
      </Stack>
    </Stack>
  );
};

export default MainGetGuide;
