import classNames from "classnames";
import Stack from "../../../../shared/ui/stack/Stack";
import styles from "./MortgageStepsBlock.module.scss";
import MortgageStepCard from "../mortgageStepCard/MortgageStepCard";
import processIcons from "../../lib/processIcons";

const MortgageStepsBlock = ({ mortgageSteps }) => {
  return (
    <Stack className={styles.mortgageStepsBlock}>
      {mortgageSteps.map((step, i) => (
        <Stack
          key={i}
          className={classNames(styles.mortgageSteps, styles[step.number])}
        >
          <MortgageStepCard
            text={step.description}
            number={i + 1}
            icon={processIcons[i]}
          />
        </Stack>
      ))}
      <Stack className={classNames(styles.mortgageSteps, styles["six"])}>
        <MortgageStepCard number={6} />
      </Stack>
    </Stack>
  );
};

export default MortgageStepsBlock;
