import { PopupModal } from 'react-calendly';
import { useState } from 'react';
import ReactDOM from 'react-dom';

const openCalendlyModal = (url) => {

  const ModalContainer = () => {
    const [isOpen, setIsOpen] = useState(true);

    const closeModal = () => {
      setIsOpen(false);
      const modalRoot = document.getElementById('calendly-modal-root');
      if (modalRoot) {
        ReactDOM.unmountComponentAtNode(modalRoot);
        document.body.removeChild(modalRoot);
      }
    };

    return (
      <PopupModal
        url={url}
        open={isOpen}
        onModalClose={closeModal}
        rootElement={document.getElementById('root')}
      />
    );
  };

  let modalRoot = document.getElementById('calendly-modal-root');
  if (!modalRoot) {
    modalRoot = document.createElement('div');
    modalRoot.id = 'calendly-modal-root';
    document.body.appendChild(modalRoot);
  }

  ReactDOM.render(<ModalContainer />, modalRoot);
};

export default openCalendlyModal;
