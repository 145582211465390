import { useTranslation } from "react-i18next";
import Stack from "../../../../shared/ui/stack/Stack";
import styles from "./BottomSlogan.module.scss";
import { motion } from "framer-motion";
import { useTranslatedData } from "../../../../app/translation/lib/useTranslatedData";

function BottomSlogan() {
  const { i18n } = useTranslation();
  const data = useTranslatedData();
  const slogan = data.bottomSlogan;

  const containerVariants = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.07,
        delayChildren: 2
      },
    },
  };

  const letterVariants = {
    hidden: { opacity: 0, scale: 0 },
    visible: { opacity: 1, scale: 1 },
  };

  return <Stack className={styles.bottomSlogan} lang={i18n.language}>
    <motion.div
      className="text-container"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      {slogan.split("").map((char, index) => (
        <motion.span key={index} variants={letterVariants}>
          {char}
        </motion.span>
      ))}
    </motion.div>
  </Stack>;
}

export default BottomSlogan;
