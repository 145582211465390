// import styles from './Slider.module.scss';
import { useEffect, useRef } from 'react';
import { reviewsData } from '../../lib/data';
import './customBootstrap.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import { Carousel } from 'bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getAllReviews, getReviewsArray } from '../../model/reduxSlice/reviewsSlice';

// import axios from '../../../../app/axios/axios';

export const Slider = () => {
    const dispatch = useDispatch();
    const carouselRef = useRef(null);
    const reviewsArray = useSelector(getReviewsArray);
    const reviews = reviewsArray ? reviewsArray : reviewsData;

    // Это чтобы слайды автоматически перелистывались. Вообще интервал работает и без этого, но он не запускается автоматически при первой загрузке страницы
    useEffect(() => {
        dispatch(getAllReviews());

        const carouselElement = carouselRef.current;

        if (carouselElement) {
            const carousel = new Carousel(carouselElement, {
                ride: 'carousel'
            });

            const handleSlid = () => {
                carousel.pause();
                carouselElement.removeEventListener('slid.bs.carousel', handleSlid);
            };
            carouselElement.addEventListener('slid.bs.carousel', handleSlid);

            return () => {
                carouselElement.removeEventListener('slid.bs.carousel', handleSlid);
            };
        }
    }, [dispatch]);

    return (
        <div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel" ref={carouselRef}>
            <div className="carousel-indicators">
                {reviews?.map((_, i) => (
                    <button
                        key={i}
                        type="button"
                        data-bs-target="#carouselExampleInterval"
                        data-bs-slide-to={i}
                        className={i === 0 ? "active" : ""}
                        aria-current={i === 0 ? "true" : undefined}
                        aria-label={`Slide ${i + 1}`}
                    ></button>
                ))}
            </div>

            <div className="carousel-inner">
                {reviews?.map((img, i) => (
                    <div
                        key={i}
                        className={`carousel-item ${i === 0 ? "active" : ""}`}
                        data-bs-interval="7000"
                    >
                        <img src={img._id ? `${process.env.REACT_APP_BASE_URL}/uploads/${img.imageUrl}` : `${process.env.PUBLIC_URL}/images/reviews/${img.path}`}
                            className="d-block w-100"
                            alt={`Slide ${i + 1}`}
                            loading="lazy"
                        />

                    </div>
                ))}
            </div>

            <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleInterval"
                data-bs-slide="prev"
                aria-label='Previous'
            >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleInterval"
                data-bs-slide="next"
                aria-label='next'
            >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    );
}