import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React from 'react'
import ReactDOM from 'react-dom/client'
import Router from './app/routes/Router'
import './app/styles/index.scss'
import reportWebVitals from './reportWebVitals'
import { I18nextProvider } from 'react-i18next';
import i18n from './app/translation/lib/i18n'
import { RateProvider } from './entities/calculator/lib/hook/useRate'
import { Provider } from 'react-redux';
import { store } from './app/redux/store';

const root = ReactDOM.createRoot(document.getElementById('root'))

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
})

root.render(
	<React.StrictMode>
		<Provider store={ store }>
			<I18nextProvider i18n={i18n}>
				<QueryClientProvider client={queryClient}>
					<RateProvider>
						<Router />
					</RateProvider>
				</QueryClientProvider>
			</I18nextProvider>
		</Provider>
	</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
