import styles from "./MortgageStepCardMobile.module.scss";
import Stack from "../../../../shared/ui/stack/Stack";
import checkmark from "../../../../shared/assets/images/checkmark.svg";

const MortgageStepCardMobile = ({ text, orientation }) => {
  return (
    <Stack
      className={styles[orientation]}
      direction="row"
      align="alignCenter"
      justify={orientation === "right" ? "justifyStart" : "justifyEnd"}
    >
      {orientation === "right" && <img src={checkmark} alt="checkmark" />}
      <p className={styles.textMortgageStepMobile}>{text}</p>
      {orientation === "left" && <img src={checkmark} alt="checkmark" />}
    </Stack>
  );
};

export default MortgageStepCardMobile;
