import { validEmail, validText } from '../../../../shared/lib/regex';
import Button from '../../../../shared/ui/button/Button';
import Input from '../../../../shared/ui/input/Input';

const Form = ({
	data,
	handleSubmit,
	register,
	submitForm,
	formState: { errors, isSubmitting },
}) => {

	return (
		<form onSubmit={handleSubmit(submitForm)}>
			<Input
				register={register}
				name='userName'
				options={{
					required: data.dataRequired.requiredName,
					pattern: {
						value: validText,
						message: data.validMessage.validName,
					},
				}}
				error={errors.userName}
				placeholder={ data.placeholder.yourName }
				aria-label='user name'
			/>
			<Input
				register={register}
				name='email'
				options={{
					required: data.dataRequired.requiredEmail,
					pattern: {
						value: validEmail,
						message: data.validMessage.validEmail,
					},
				}}
				error={errors.email}                                      
				type='email'
				placeholder={ data.placeholder.yourEmail }
			/>

			<Button 
				type='submit' 
				disabled={isSubmitting} 
			>
				{ data.buttonGuide }
			</Button>
		</form>
	)
}

export default Form
