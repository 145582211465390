import axios from 'axios';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

export const useGetGuide = (setIsSubmitSuccessful) => {
    const { formState, handleSubmit, register, reset, setValue } = useForm({
        mode: 'onChange',
    });

    const [isError, setIsError] = useState(false);

    const submitForm = useCallback(
        async data => {
            try {
                await axios.post(`${process.env.REACT_APP_BASE_URL}/send-email`, data); 
                setIsSubmitSuccessful(true);
                setIsError(false);
                reset();
            } catch (error) {
                setIsError(true);
                console.log(error);
            }
        },
        [reset, setIsSubmitSuccessful]
    );

    return useMemo(
        () => ({
            formState,
            handleSubmit,
            register,
            isError,
            submitForm,
            setValue,
        }),
        [
            formState,
            handleSubmit,
            register,
            isError,
            submitForm,
            setValue,
        ]
    );
};
