import { configureStore } from '@reduxjs/toolkit';
import reviewsSlice from '../../entities/reviews/model/reduxSlice/reviewsSlice';
import  adminSlice  from '../../features/auth/model/reduxSlice/adminSlice'

export const store = configureStore({
    reducer: {
        reviews: reviewsSlice,
        admin: adminSlice
    }
})
