import { axiosClassic } from '../api/api'
import { getStatisticUrl } from '../api/api.helpers'

export const StatisticService = {
	async getAll() {
		return axiosClassic.get(getStatisticUrl(``))
	},

	async update(title, count) {
		return axiosClassic.put(getStatisticUrl(`/${title}`), {count})
	},
}
