import { useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useCalculateMortgage } from './useCalculateMortgage'
import { addCommas, removeCommas } from '../../../../shared/lib/utils/string/normalize'

export const useCalculateBlock = (
	name,
	max,
	setMaxMonthPayment,
	setMaxMortgage
) => {
	const { watch, setValue } = useFormContext()

	const valueInput = watch(name)
	const valueRange = watch(`${name}Range`)

	const income = watch('incomeRange')
	const liabilities = watch('liabilitiesRange')
	const duration = watch('durationRange')

	const handleChangeCalculate = useCallback(
		event => {
			const { value } = event.target

			if (value) setValue(`${name}Range`, removeCommas(value))

			if (removeCommas(value) > max) {
				setValue(name, addCommas(max))
				setValue(`${name}Range`, max)
			}

			if (!value) {
				setValue(`${name}Range`, 0)
				setValue('durationRange', 5)
			}
		},
		[name, setValue, max]
	)

	const handleChangeRange = useCallback(
		event => {
			setValue(name, addCommas(event.target.value))
		},
		[name, setValue]
	)

	useCalculateMortgage(
		income,
		duration,
		liabilities,
		setMaxMonthPayment,
		setMaxMortgage
	)

	return useMemo(
		() => ({
			valueInput,
			valueRange,
			handleChangeCalculate,
			handleChangeRange,
		}),
		[valueInput, valueRange, handleChangeCalculate, handleChangeRange]
	)
}
