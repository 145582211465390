import SectionTitle from "../../../../shared/ui/sectionTitle/SectionTitle";
import Stack from "../../../../shared/ui/stack/Stack";
import MortgageStepsBlock from "../mortgageStepsBlock/MortgageStepsBlock";
import styles from "./MortgageProcess.module.scss";
import ContentText from "../../../../shared/ui/contentText/ContentText";
import { useTranslatedData } from "../../../../app/translation/lib/useTranslatedData";
import MortgageStepsBlockMobile from "../mortgageStepsBlockMobile/MortgageStepsBlockMobile";

const MortgageProcess = () => {
  const data = useTranslatedData();
  const mortgageSteps = data.mortgageSteps;

  return (
    <Stack
      id="mortgageProcess"
      className={styles.mortgageProcess}
      direction="column"
    >
      <SectionTitle text={data.mortgageProcess} color="secondary" />

      <ContentText content={data.textMortgageProcess} />
      <MortgageStepsBlockMobile mortgageSteps={mortgageSteps} />
      <MortgageStepsBlock mortgageSteps={mortgageSteps} />
    </Stack>
  );
};

export default MortgageProcess;
