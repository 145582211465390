import { useForm } from 'react-hook-form';
import Button from '../../../../shared/ui/button/Button';
import Input from '../../../../shared/ui/input/Input';
import Stack from '../../../../shared/ui/stack/Stack';
import styles from './StatisticAdmin.module.scss';
import Loader from '../../../../shared/ui/loader/Loader';
import { useStatistic } from '../../lib/hooks/useStatistic';


const texts = {
	experience: 'years of experience',
	mortgages: 'arranged mortgages',
	mortgageBanker: 'years as a TOP Mortgage Banker in CZ Bank'
}

const getText = (text) => {
	return texts[text]
}

const StatisticAdminItem = ({ item }) => {

	const {reset} = useForm({
		mode: 'onChange',
	});

	const { isLoad, onSubmit } = useStatistic(reset);

	const { register, handleSubmit, formState: { errors } } = useForm({
		mode: 'onChange',
	})


	return (
		<Stack direction='column' max='' className={styles.item}>
			<p>
				{getText(item.text)}: {item.count}
			</p>

			<Input
				register={register}
				name={item.text}
				placeholder='Enter new count'
				type='number'
				options={{ required: 'This field is required.' }}
				error={errors[item.text]}
			/>

			<Button onClick={handleSubmit(data => onSubmit(data, item.text))}>
				{isLoad ? <Loader width='15'/> : 'Save'}
			</Button>
		</Stack>
	)
}


export default StatisticAdminItem
