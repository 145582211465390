import { useTranslation } from 'react-i18next'
import { useTranslatedData } from '../../../../app/translation/lib/useTranslatedData'
import { getText } from '../../../../shared/lib/utils/string/normalize'
import Stack from '../../../../shared/ui/stack/Stack'
import styles from './StatisticItem.module.scss'

const StatisticItem = ({ item }) => {
	const data = useTranslatedData()
	const { i18n } = useTranslation()

	const text = item.text
	const years = ['год', 'года', 'лет']
	const loans = ['кредит', 'кредита', 'кредитов']

	return (
		<Stack
			className={styles.statisticItem}
			direction='column'
			justify='justifyStart'
		>
			<span className={styles.statisticItemCount}>{item.count}</span>
			<span className={styles.statisticItemText}>
				{i18n.language === 'ru'
					? getText(item.count, text, years, loans, data[text])
					: data[text]}
			</span>
		</Stack>
	)
}

export default StatisticItem
