import styles from "./ContactButton.module.scss";

const ContactButton = ({ Icon, link }) => {
  return (
    <a
      className={styles.contactButton}
      href={link} target="_blank" rel="noopener noreferrer"
    >
      <Icon className={styles.contactIcon} />
    </a>
  );
};

export default ContactButton;
