import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllReviews, addReview, deleteReview, getReviewsArray } from '../../../../entities/reviews/model/reduxSlice/reviewsSlice';
import styles from './Reviews.module.scss';
import Button from '../../../../shared/ui/button/Button';

export const Reviews = () => {
    const dispatch = useDispatch();
    const reviewsArray = useSelector(getReviewsArray);

    const [file, setFile] = useState(null);
    const [showWarning, setShowWarning] = useState(false);
    const [fileSizeWarning, setFileSizeWarning] = useState(false);

    useEffect(() => {
        dispatch(getAllReviews());
    }, [dispatch]);

    const upload = async () => {
        try {
            if (file) {
                setShowWarning(false);

                const newFileName = `image_${Date.now()}.${file.name.split('.').pop()}`;
                const renamedFile = new File([file], newFileName, { type: file.type });

                const formData = new FormData();
                formData.append('file', renamedFile);
                const result = await axios.post(`${process.env.REACT_APP_BASE_URL}/upload`, formData);

                return result.data.url.split('/')[2];
            }
        } catch (err) {
            console.log('upload error', err)
        }
    }

    const setReview = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            if (selectedFile.size > 5 * 1024 * 1024) { // 5MB
                setFileSizeWarning(true);
                setShowWarning(false);
                setFile(null);
                return;
            }
            setFileSizeWarning(false);
            setShowWarning(false);
            setFile(selectedFile);
        }
    }

    const checkAndAdd = () => {

        if (reviewsArray?.length >= 10) {
            return;
        }
        else if (file === null) {
            setShowWarning(true);
        }
        else if (file?.name.length >= 1) {
            addImg();
        }
    }

    const addImg = async () => {
        const imageUrl = await upload();

        try {
            const values = {
                "imageUrl": file ? imageUrl : ''
            }
            dispatch(addReview(values))
            setFile(null)
        } catch (err) {
            console.log('create news error', err)
        }
    }

    const deleteImg = async (id) => {
        dispatch(deleteReview(id))
        dispatch(getAllReviews())
    }

    return (
        <div className={styles.container}>
            <label className={styles.inputFile}>
                <input 
                    type="file" name="file" 
                    accept="image/*, .png, .jpg, .jpeg, .jfif, .pjpeg, .pjp, .tif, .tiff, .bmp, .ico, .cur, .gif, .webp, .pdf, .svg, .webm, .avi, .mpeg, .mp4" 
                    onChange={setReview} 
                />
                {!file?.name && <span>Choose file</span>}
                {file?.name && <span>{file.name}</span>}
            </label>

            {fileSizeWarning && <p className={styles.smallWarn}>
                The file size should not exceed 5MB
            </p>}

            {showWarning && <p className={styles.smallWarn}>
                Please select a review to upload
            </p>}
            <div className={styles.addBtnContainer}>
                <Button onClick={checkAndAdd} >
                    Add new review
                </Button>
            </div>

            {reviewsArray?.length >= 10 && (
                <p className={styles.warning}>
                    You have reached the limit of 10 reviews. To add new ones, you must first delete 1 or more.
                </p>
            )}

            {reviewsArray?.length < 10 && (
                <p className={styles.info}>
                    You may add {10 - reviewsArray?.length} review(s).
                </p>
            )}

            <div className={styles.reviewsContainer}>
                {reviewsArray?.map((img, i) => (
                    <div className={styles.imgContainer} key={img._id}>
                        <div>
                            <img src={`${process.env.REACT_APP_BASE_URL}/uploads/${img.imageUrl}`} alt='reviews' />
                        </div>
                        <p>
                            <span>
                                Review {i + 1}
                            </span>
                            <Button onClick={() => deleteImg(img._id)} variant="outline" className={styles.delBtn}>
                                Delete
                            </Button>
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
}