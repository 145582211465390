import Stack from "../../../../shared/ui/stack/Stack";
import styles from "./ResultBlock.module.scss";
import Button from "../../../../shared/ui/button/Button";
import { useTranslatedData } from "../../../../app/translation/lib/useTranslatedData";

const ResultBlock = ({ result, titleResult, onRestart }) => {
  const data = useTranslatedData();
  
  return (
    <Stack
      direction="column"
      className={styles.resultText}
    >
      <h6>{titleResult}</h6>
      <p>{result}</p>
      <Stack className={styles.questionButtons}>
        <Button onClick={onRestart} variant="outline" aria-label='restart'>
        {data.buttonStartOver}
        </Button>
      </Stack>
    </Stack>
  );
};

export default ResultBlock;
