import Stack from "../../shared/ui/stack/Stack";
import styles from "./AboutMe.module.scss";
import ContentText from "../../shared/ui/contentText/ContentText";
import SectionTitle from "../../shared/ui/sectionTitle/SectionTitle";
import { useTranslatedData } from "../../app/translation/lib/useTranslatedData";
import { useEffect } from "react";

const AboutMe = () => {
  const data = useTranslatedData();

  useEffect(() => {
    document.fonts.ready.then(() => {
      document.body.classList.add("loaded");
    });
  }, []);

  return (
    <Stack
      id="aboutMe"
      className={styles.aboutMe}
      direction="column"
    >
      <SectionTitle text={data.aboutMe} />
      <ContentText content={data.textAboutMe} />
    </Stack>
  );
};

export default AboutMe;