import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import styles from "./LazyLoading.module.scss";

const LazyLoading = ({
  children,
  delay = 500,
  height,
  threshold = 0,
  rootMargin = "0px",
  triggerOnce = false,
}) => {
  const { ref, inView } = useInView({
    triggerOnce: triggerOnce,
    threshold: threshold,
    rootMargin: rootMargin,
  });
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    let timer;
    if (inView) {
      timer = setTimeout(() => {
        setShowContent(true);
      }, delay);
    } else if (!triggerOnce) {
      setShowContent(false);
    }
    return () => clearTimeout(timer);
  }, [inView, delay, triggerOnce]);

  return (
    <div
      className={styles.lazyLoadContainer}
      ref={ref}
      style={{ height: height }}
    >
      {showContent && children}
    </div>
  );
};

export default LazyLoading;
