import styles from "./Header.module.scss";
import useWindowSize from "../../../../shared/hooks/useWindowSize";
import HeaderPersonPhoto from "../headerPersonPhoto/HeaderPersonPhoto";
import BottomSlogan from "../bottomSlogan/BottomSlogan";
import HeaderTop from "../headerTop/HeaderTop";
import HeaderTopWithMenu from "../headerTopWithMenu/ui/headerWithMenu/HeaderTopWithMenu";
import Stack from "../../../../shared/ui/stack/Stack";
import HeaderSideSlogans from "../headerSideSlogans/HeaderSideSlogans";
import BigHeaderInfoBlock from "../bigHeaderInfoBlock/BigHeaderInfoBlock";

const Header = () => {
    const screenSize = useWindowSize();
    const tabletL = 1024;

    return (
        <Stack id="home" role='banner' className={styles.headerContainer} direction="column">
            {screenSize.width > tabletL ? <HeaderTopWithMenu /> : <HeaderTop />}

            <Stack className={styles.headerContent} justify="justifyBetween">
                <HeaderPersonPhoto />

                <Stack
                    className={styles.headerInfoContainer}
                    direction="column"
                    align="alignEnd"
                >
                    <HeaderSideSlogans />
                    {screenSize.width > tabletL && <BigHeaderInfoBlock />}
                </Stack>
            </Stack>

            {screenSize.width <= tabletL && <BottomSlogan />}
        </Stack>
    );
};

export default Header;
