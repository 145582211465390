const TelegramIcon = () => (
  <svg
    viewBox="0 0 20 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.524 16.4715L7.83471 11.7168L16.3569 3.93849C16.7342 3.59004 16.2792 3.42143 15.7799 3.72492L5.2603 10.4579L0.710704 8.99666C-0.265795 8.71565 -0.276892 8.02999 0.932635 7.53541L18.6539 0.611336C19.4639 0.240403 20.2407 0.813663 19.93 2.07259L16.9117 16.4715C16.7009 17.4944 16.0906 17.7417 15.2472 17.2696L10.6532 13.83L8.44502 15.9994C8.1898 16.258 7.97896 16.4715 7.524 16.4715Z" />
  </svg>
);

export default TelegramIcon;
