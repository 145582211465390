import { CurrentRateAdmin } from "../../entities/calculator/ui/currentRateAdmin/CurrentRateAdmin";
import Stack from "../../shared/ui/stack/Stack";
import Auth from "../../features/auth/ui/authorization/auth";
import { Reviews } from "../../features/reviews-uploader/ui/reviews/Reviews";
import SectionTitle from "../../shared/ui/sectionTitle/SectionTitle";
import { useSelector } from "react-redux";
import { getAdmin } from "../../features/auth/model/reduxSlice/adminSlice";
import StatisticAdmin from '../../entities/statistic/ui/admin/StatisticAdmin';
import { Helmet } from 'react-helmet';
import { useTranslatedData } from "../../app/translation/lib/useTranslatedData";
import { useEffect } from "react";


const AdminPage = () => {
  const data = useTranslatedData();
  const admin = useSelector(getAdmin);

  useEffect(() => {
    const token = admin.token;
    if (token) {
      localStorage.setItem('adminToken', token);
    }
  }, [admin.token]);

  const savedToken = localStorage.getItem('adminToken');
  
  if (!admin.token && !savedToken) {
    return (
      <Stack direction="column" max>
        <Auth />
      </Stack>
    );
  }

  return (
    <>
      <Helmet>
				<title>
					{ data.adminPageTitle }
				</title>
				<meta name="robots" content="noindex,nofollow" />
				<link rel="canonical" href="https://ramortgage.cz/manage" />
			</Helmet>

      <Stack direction="column" max>
        <Auth />
        <Stack direction="column" max>
          <CurrentRateAdmin />
          <SectionTitle text='Manage Reviews' />
          <Reviews />
        </Stack>
        <StatisticAdmin />
      </Stack>
    </>
  );
}

export default AdminPage;