import ReactDOM from 'react-dom';
import Stack from '../stack/Stack';
import styles from './Modal.module.scss';

const Modal = ({ showModal, children }) => {
	if (!showModal) return null

	return ReactDOM.createPortal(
		<Stack className={styles.modalWrapper} role='dialog' aria-modal='true'>
			{children}
		</Stack>,
		document.body
	)
}

export default Modal;
