import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const useChangeLanguage = () => {
    const { i18n } = useTranslation();

    const changeLanguage = () => {
        const newLang = i18n.language === 'en' ? 'ru' : 'en';
        i18n.changeLanguage(newLang);
        localStorage.setItem('language', newLang); 
    };

    useEffect(() => {
        const savedLang = localStorage.getItem('language');
        if (savedLang && savedLang !== i18n.language) {
            i18n.changeLanguage(savedLang); 
        }
    }, [i18n]);

    return changeLanguage;
};

export default useChangeLanguage;