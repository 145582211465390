import { useEffect, useState } from 'react'

export const useModal = () => {
	const [showModal, setShowModal] = useState(false)
	const [seen, setSeen] = useState(false)
	const hasGuideSent = localStorage.getItem('hasGuideSent')

	const onScrollOpen = () => {
		setShowModal(() => {
			if (window.scrollY >= 1000 && !seen && !hasGuideSent) {
				return true
			} else {
				return false
			}
		})
	}

	const onOpen = () => {
		setShowModal(true)
	}

	useEffect(() => {
		if (showModal) {
			document.body.style.overflow = 'hidden'
		}
	}, [showModal])

	const toggleShowModal = () => {
		setShowModal(!showModal)
		setSeen(true)
		document.body.style.overflow = 'unset'
	}

	const toggleShowModalSuccess = () => {
		setShowModal(!showModal)
		setSeen(true)
		document.body.style.overflow = 'unset'
		localStorage.setItem('hasGuideSent', 'true')
	}

	return {
		showModal,
		onScrollOpen,
		onOpen,
		toggleShowModal,
		toggleShowModalSuccess,
	}
}
