import MyServices from "../../../../entities/myServices/ui/myServices/MyServices";
import Statistic from "../../../../entities/statistic/ui/staisticList/Statistic";
import Stack from "../../../../shared/ui/stack/Stack";
import BottomSlogan from "../bottomSlogan/BottomSlogan";
import styles from "./BigHeaderInfoBlock.module.scss";

const BigHeaderInfoBlock = () => {
  return (
    <Stack
      className={styles.bigHeaderInfoBlock}
      direction="column"
      justify="justifyBetween"
      align="alignEnd"
    >
      <Statistic />
      <MyServices />
      <BottomSlogan />
    </Stack>
  );
};

export default BigHeaderInfoBlock;
