import Button from "../../../../shared/ui/button/Button";
import Stack from "../../../../shared/ui/stack/Stack";
import Input from "../../../../shared/ui/input/Input";
import styles from './CurrentRateAdmin.module.scss'
import { saveCurrentRate } from "../../lib/fetch";
import { FormProvider, useForm } from "react-hook-form";
import Loader from "../../../../shared/ui/loader/Loader"
import SectionTitle from "../../../../shared/ui/sectionTitle/SectionTitle";
import { useRate } from "../../lib/hook/useRate";
import { useState } from "react";


export const CurrentRateAdmin = () => {

    const methods = useForm({ mode: 'onChange' });
    const { register, handleSubmit, setValue, reset, formState: { errors } } = methods;
    const { currentRate, setCurrentRate, isLoading, setIsLoading } = useRate();
    const [isLoad, setIsLoad] = useState(false);

    const onSubmit = (data) => {
        const rate = parseFloat(data.newRate);
        try {
            setIsLoad(true);
            saveCurrentRate(rate, setCurrentRate);
            reset({ newRate: "" });
        } catch (error) {
            console.error('Failed to save or fetch current rate:', error);
        } finally {
            setIsLoading(false);
            setIsLoad(false)
        }
    };

    return (
        <Stack direction="column" max>
            <SectionTitle text="Change Interest Rate" />
            <FormProvider {...methods}>
                <form
                    className={styles.rateForm}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    {isLoading ?
                        <Stack>
                            <p className={styles.rate}>
                                CURRENT VALUE :
                            </p>
                            <Loader width="15"/>
                        </Stack>
                        :
                        <p className={styles.rate}>
                            CURRENT VALUE : {currentRate}%
                        </p>}
                    <Input
                        name="newRate"
                        valueInput={currentRate}
                        register={register}
                        setValue={setValue}
                        variant='form'
                        placeholder="Enter new rate"
                        options={{
                            required: 'This field is required.',
                            pattern: {
                                value: /^\d+(\.\d+)?$/,
                                message: 'This field must contain only numbers'
                            }
                        }}
                        error={errors.newRate}
                    />
                    <Button type='submit'>
                        {isLoad ? <Loader width="15"/> : "Save"}
                    </Button>
                </form>
            </FormProvider>
        </Stack>
    );
}

