const PhoneIcon = () => (
  <svg
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.76944 7.82361C5.16945 10.575 7.425 12.8306 10.1764 14.2306L12.3153 12.0917C12.5875 11.8194 12.9667 11.7417 13.3069 11.8486C14.3958 12.2083 15.5625 12.4028 16.7778 12.4028C17.0356 12.4028 17.2829 12.5052 17.4652 12.6875C17.6476 12.8699 17.75 13.1172 17.75 13.375V16.7778C17.75 17.0356 17.6476 17.2829 17.4652 17.4652C17.2829 17.6476 17.0356 17.75 16.7778 17.75C12.3943 17.75 8.19043 16.0087 5.09087 12.9091C1.99131 9.80957 0.25 5.60566 0.25 1.22222C0.25 0.964373 0.35243 0.717084 0.534757 0.534757C0.717084 0.35243 0.964373 0.25 1.22222 0.25H4.625C4.88285 0.25 5.13014 0.35243 5.31247 0.534757C5.49479 0.717084 5.59722 0.964373 5.59722 1.22222C5.59722 2.4375 5.79167 3.60417 6.15139 4.69306C6.25833 5.03333 6.18056 5.4125 5.90833 5.68472L3.76944 7.82361Z" />
  </svg>
);

export default PhoneIcon;
