import Stack from "../../../../../../shared/ui/stack/Stack";
import styles from './ItemHeaderTopWithMenu.module.scss';

const ItemHeaderTopWithMenu = ({ title, titleKey }) => {

	return (
		<Stack>
			<a role='menuitem' className={styles.titleItem} href={`#${titleKey}`}>
				{title}
			</a>
		</Stack>
	)
}

export default ItemHeaderTopWithMenu
