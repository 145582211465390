import Input from '../../../../shared/ui/input/Input'
import Button from '../../../../shared/ui/button/Button'
import styles from './auth.module.scss';
import Stack from '../../../../shared/ui/stack/Stack'
import SectionTitle from '../../../../shared/ui/sectionTitle/SectionTitle'
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { refreshAdminPassword, getErrorMessage } from '../../model/reduxSlice/adminSlice'
import { useSelector } from 'react-redux';
import { useState } from 'react';
import Loader from '../../../../shared/ui/loader/Loader';


const RefreshPassword = () => {
    const { resetToken } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const errorMessage = useSelector(getErrorMessage);
    const methods = useForm({ mode: 'onChange' });
    const { register, handleSubmit, setValue, reset, formState: { errors } } = methods;
    const [isLoading, setIsLoading] = useState(false);

    const handleRefreshPasswordSubmit = async (data) => {
        const { newPassword } = data;
        setIsLoading(true)
        try {
            await dispatch(refreshAdminPassword({ resetToken, newPassword }));
            reset({ newPassword: "" });
            navigate('/manage');
        } catch (error) {
            console.error('Failed to refresh password:', error);
            setIsLoading(false)
        }
    };

    return (
        <Stack direction="column" max>
            <SectionTitle text="Reset your password" />
            <FormProvider {...methods}>
                <form className={styles.loginForm} onSubmit={handleSubmit(handleRefreshPasswordSubmit)}>
                    <Input
                        name="newPassword"
                        register={register}
                        setValue={setValue}
                        variant='form'
                        placeholder="New Password"
                        options={{required: 'This field is required.'}}
                        error={errors.newPassword}
                    />
                    {errorMessage && <p className={styles.error}>{errorMessage}</p>}
                    <Button type='submit'>
                        {isLoading ? <Loader width="15"/> : "Save new password"}
                    </Button>
                </form>
            </FormProvider>
        </Stack>
    );
};


export default RefreshPassword;
