import Logo from '../../../../shared/ui/logo/Logo';
import Stack from '../../../../shared/ui/stack/Stack';
import styles from './HeaderTop.module.scss';

function HeaderTop() {

    return (
        <Stack className={styles.headerTop} justify='justifyEnd' align='alignEnd'>
            <Logo />
        </Stack>
    )
}

export default HeaderTop;
