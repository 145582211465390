import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { request } from '../../../../shared/api/api.helpers';

export const loginAdmin = createAsyncThunk('/loginProfile',
    async ({ login, password }, { rejectWithValue }) => {
        try {
            const response = await axios.post(request.auth.post.login, { login, password },
                {
                    headers: {
                        'accept': 'application/json',
                        'content-type': 'application/json',
                    }
                }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data);
        }
    }
);

export const resetAdminPassword = createAsyncThunk('/reset-password',
    async (email, { rejectWithValue }) => {
        try {
            const response = await axios.post(request.auth.post.resetPassword, { email },
                {
                    headers: {
                        'accept': 'application/json',
                        'content-type': 'application/json',
                    }
                }
            );
            return response.data.message;
        } catch (error) {
            return rejectWithValue(error.response?.data);
        }
    }
);

export const refreshAdminPassword = createAsyncThunk('/refresh-password',
    async ({ resetToken, newPassword }, { rejectWithValue }) => {
        try {
            const response = await axios.post(request.auth.post.refreshPassword, { resetToken, newPassword },
                {
                    headers: {
                        'accept': 'application/json',
                        'content-type': 'application/json',
                    }
                }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data);
        }
    }
);

export const adminSlice = createSlice({
    name: 'admin',
    initialState: {
        admin: {},
        errorMessage: ''
    },
    reducers: {
        saveAdmin: (state, action) => {
            state.admin = action.payload;
        },
        removeAdmin: (state) => {
            state.admin = {};
        },
        setErrorMessage: (state, action) => {
            state.errorMessage = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginAdmin.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loginAdmin.fulfilled, (state, action) => {
                state.admin = action.payload;
                state.errorMessage = '';
            })
            .addCase(loginAdmin.rejected, (state, action) => {
                state.errorMessage = action.payload ? action.payload : 'Incorrect login or password';
            })
            .addCase(resetAdminPassword.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(resetAdminPassword.fulfilled, (state) => {
                state.errorMessage = '';
            })
            .addCase(resetAdminPassword.rejected, (state, action) => {
                state.errorMessage = action.payload ? action.payload : 'User with this email not found.';
            })
            .addCase(refreshAdminPassword.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(refreshAdminPassword.fulfilled, (state) => {
                state.errorMessage = '';
            })
            .addCase(refreshAdminPassword.rejected, (state, action) => {
                state.errorMessage = action.payload ? action.payload : 'Invalid or expired token.';
            });
    },
});

export const { saveAdmin, removeAdmin, setErrorMessage } = adminSlice.actions;
export const getAdmin = state => state.admin.admin;
export const getErrorMessage = state => state.admin.errorMessage;
export default adminSlice.reducer;
