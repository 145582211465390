import { useTranslatedData } from '../../../../app/translation/lib/useTranslatedData'
import openCalendlyModal from '../../../../features/сalendly/get-termin'
import headerBtnText from '../../../../shared/assets/images/headerBtnText.svg'
import serviceIconOne from '../../../../shared/assets/images/serviceIconOne.svg'
import serviceIconTwo from '../../../../shared/assets/images/serviceIconTwo.svg'
import useWindowSize from '../../../../shared/hooks/useWindowSize'
import Button from '../../../../shared/ui/button/Button'
import SectionTitle from '../../../../shared/ui/sectionTitle/SectionTitle'
import Stack from '../../../../shared/ui/stack/Stack'
import ServiceCard from '../serviceCard/ServiceCard'
import styles from './MyServices.module.scss'

const MyServices = () => {
	const screenSize = useWindowSize()
	const data = useTranslatedData()
	const tabletL = 1024

	const handleButtonClick = () => {
		openCalendlyModal('https://calendly.com/czech-mortgage/consultation?back=1&month=2024-07')
	}

  	return (
    <Stack id="myServices" className={styles.myServices} direction="column">
      {screenSize.width <= tabletL && <SectionTitle text={data.myServices} />}

			<Stack className={styles.myServicesBlock}>
				<ServiceCard
					text={data.businessLoan}
					icon={serviceIconOne}
					delay={0}
					handleButtonClick={handleButtonClick}
				/>

				{screenSize.width > tabletL && (
					<ServiceCard
						text='headerBtn'
						icon={headerBtnText}
						delay={0.5}
						handleButtonClick={handleButtonClick}
					/>
				)}

				<ServiceCard
					text={data.mortgageConsultation}
					icon={serviceIconTwo}
					delay={1}
					handleButtonClick={handleButtonClick}
				/>
			</Stack>

			{screenSize.width <= tabletL && (
				<Button
					onClick={handleButtonClick}
					aria-label='Book a FREE consultation'
				>
					{data.buttonBookFree}
				</Button>
			)}
		</Stack>
	)
}

export default MyServices
