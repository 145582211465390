import { useSpring, animated } from 'react-spring';
import MenuItem from '../menuItems/MenuItem';
import Stack from '../../../../shared/ui/stack/Stack';
import styles from './SlideMenu.module.scss';
import { useTranslatedData } from '../../../../app/translation/lib/useTranslatedData';

const SlideMenu = ({ isOpen, onClose }) => {
    const data = useTranslatedData();
    const menuItems = data.titleMenu;

    const slideInStyles = useSpring({
        transform: isOpen ? 'translateY(0%)' : 'translateY(100%)',
        opacity: isOpen ? 1 : 0,
        config: { duration: 650 }
    });

    return (
        <animated.div
            className={styles.slideMenu}
            style={slideInStyles}>
            <Stack justify='justifyEnd' align='alignCenter'>
                <ul className={styles.menuList} role="menu" aria-orientation="vertical">
                    {Object.keys(menuItems).map((key) =>
                        <MenuItem
                            key={key}
                            itemKey={key}
                            item={menuItems[key]}
                            onClick={onClose}
                        />
                    )}
                </ul>
            </Stack>
        </animated.div>
    );
};

export default SlideMenu;
