import styles from "./ContentText.module.scss";

const ContentText = ({ content }) => {
  const paragraphs = content
    ? content.split("\n").map((text, index) => (
        <p key={index} className={styles.paragraph}>
          {text}
        </p>
      ))
    : null;

  return (
    <div className={styles.contentText} direction="column">
      {paragraphs}
    </div>
  );
};

export default ContentText;