import axios from 'axios';
import { request } from '../../../shared/api/api.helpers';

const getCurrentRate = async ( setCurrentRate, setIsLoading ) => {
    try {
        const response = await axios.get(request.rate.get.getCurrentRate);
        if (response.status === 200) {
            setCurrentRate(response.data.rate);
        } else {
            console.error('Failed to fetch current rate:', response.status);
        }
    } catch (error) {
        console.error('Failed to fetch current rate:', error);
    } finally {
        setIsLoading(false);
    }
};

const saveCurrentRate = async (rate, setCurrentRate) => {
    try {
        const response = await axios.post(request.rate.post.saveCurrentRate, { rate },
            {
                headers: {
                    'accept': 'application/json',
                    'content-type': 'application/json',
                }
            }
        )
        if (response.status === 200) {
            setCurrentRate(response.data.rate);
        } else {
            console.error('Failed to save current rate:', response.status);
        }
    } catch (error) {
        console.error('Failed to save current rate:', error);
    } finally {
        setCurrentRate(rate);
    }
};

export { getCurrentRate, saveCurrentRate };

