import { useState, useEffect } from "react";
import Input from '../../../../shared/ui/input/Input';
import Stack from '../../../../shared/ui/stack/Stack';
import { useCalculateBlock } from '../../lib/hook/useCalculateBlock';
import Range from '../range/Range';
import styles from './CalculatorBlock.module.scss';

const CalculatorBlock = ({
	register,
	setValue,
	item: { name, title, currency, min, max },
	setMaxMonthPayment,
	setMaxMortgage,
}) => {
	const [valueTrack, setValueTrack] = useState(0);
	const { valueInput, valueRange, handleChangeCalculate, handleChangeRange } = useCalculateBlock(name, max, setMaxMonthPayment, setMaxMortgage);

	useEffect(() => {
		const targetValues = {
			income: 250000,
			liabilities: 50000,
			duration: 15,
			incomeRange: 250000,
			liabilitiesRange: 50000,
			durationRange: 15,
		};

		const totalSteps = 100;
		const stepIncrement = {
			income: targetValues.income / totalSteps,
			liabilities: targetValues.liabilities / totalSteps,
			duration: targetValues.duration / totalSteps,
			incomeRange: targetValues.incomeRange / totalSteps,
			liabilitiesRange: targetValues.liabilitiesRange / totalSteps,
			durationRange: targetValues.durationRange / totalSteps,
		};

		let currentValues = {
			income: 0,
			liabilities: 0,
			duration: 0,
			incomeRange: 0,
			liabilitiesRange: 0,
			durationRange: 0,
		};

		const intervalId = setInterval(() => {
			let allValuesReached = true;

			for (const key in currentValues) {
				currentValues[key] += stepIncrement[key];

				if (currentValues[key] > targetValues[key]) {
					currentValues[key] = targetValues[key];
				} else {
					allValuesReached = false;
				}

				setValue(key, Math.round(currentValues[key]));

				if (key.includes('Range')) {
					const valueTrack = (currentValues[key] - min) / (max - min);
					setValueTrack(valueTrack);
				}
			}

			if (allValuesReached) {
				clearInterval(intervalId);
			}
		},20);

		return () => clearInterval(intervalId);
	}, [setValue, min, max]);

	return (
		<Stack direction='column' className={styles.calculatorBlock}>
			<Stack className={styles.calculatorInputBlock}>
				<label className={styles.label}>{title}</label>
				<Input
					valueInput={valueInput}
					setValue={setValue}
					register={register}
					name={name}
					variant='calculate'
					onInput={handleChangeCalculate}
					aria-label={name}
				/>
				<p className={styles.txt}>{currency}</p>
			</Stack>
			<Range
				register={register}
				name={`${name}Range`}
				valueInput={valueInput}
				valueRange={valueRange}
				onInput={handleChangeRange}
				valueTrack={valueTrack}
				min={min}
				max={max}
				setValueTrack={setValueTrack} 
			/>
		</Stack>
	);
};

export default CalculatorBlock;

