import { useEffect } from 'react'
import { useRate } from './useRate';

export const useCalculateMortgage = (
	income,
	duration,
	liabilities,
	setMaxMonthPayment,
	setMaxMortgage
) => {

	const { currentRate } = useRate()
	const MAX_PERCENT = 100;
	const DTICOEFF = 0.5; //коэффициент DTI - единственное более менее подходящее
	const MONTHS = 12;

	function PV(rate, nper, pmt) {
		return (pmt * (1 - Math.pow(1 + rate, -nper))) / rate
	}

	useEffect(() => {
		if (income > 0 && duration > 0) {
			const monthlyInterestRate = currentRate / MAX_PERCENT / MONTHS
			const n = duration * MONTHS
			const availablePayment = (income - (liabilities / DTICOEFF)) * DTICOEFF
			setMaxMonthPayment(availablePayment)

			const maxLoan = PV(monthlyInterestRate, n, availablePayment)
			setMaxMortgage(Math.round(maxLoan))
		} else {
			setMaxMonthPayment(0)
			setMaxMortgage(0)
		}
	}, [
		currentRate,
		duration,
		income,
		liabilities,
		setMaxMonthPayment,
		setMaxMortgage,
	])
}
