import classNames from 'classnames'
import { motion } from 'framer-motion'
import styles from './ServiceCard.module.scss'

const ServiceCard = ({ text, icon, delay, handleButtonClick }) => {
	const variants = {
		hidden: { opacity: 0, y: -50 },
		visible: { opacity: 1, y: 0 },
	}

	const serviceCardClass = classNames({
		[styles.serviceCard]: true,
		[styles.serviceCardBtn]: text === 'headerBtn',
	})

	return (
		<motion.div
			className={styles.motion}
			initial='hidden'
			animate='visible'
			variants={variants}
			transition={{ duration: 0.5, delay: delay }}
		>
			<button
				className={serviceCardClass}
				onClick={handleButtonClick}
				aria-label='Book a FREE consultation'
			>
				<img src={icon} alt={text} />
				{text !== 'headerBtn' && text}
			</button>
		</motion.div>
	)
}

export default ServiceCard
