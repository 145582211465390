import styles from "./ContactsBlock.module.scss";
import Stack from "../../../../shared/ui/stack/Stack";
import ContactButton from "../contactButton/ContactButton";
import allSocialIcons from "../../lib/allSocialIcons";

const ContactsBlock = () => {
  return (
    <Stack className={styles.contactsBlock}>
      {allSocialIcons.map(({ name, component: Icon, link }) => (
        <ContactButton key={name} Icon={Icon} link={link} />
      ))}
    </Stack>
  );
};

export default ContactsBlock;
