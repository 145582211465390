export const getContentType = () => ({
	'Content-Type': 'application/json',
})

const baseUrl = `${process.env.REACT_APP_BASE_URL}`
const formatRequest = (group, request, address = baseUrl) => {
    return group ? `${address}/${group}/${request}` : `${address}/${request}`;
};

const formatAuth = request => formatRequest('', request);
const formatRate = request => formatRequest('rate', request);
const formatEmail = request => formatRequest('send-email', request);

export const request = {
	auth: {
		post: {
			registration: formatAuth('registration'),
			login: formatAuth('login'),
			resetPassword: formatAuth('reset-password'),
			refreshPassword: formatAuth('refresh-password'),
		}
	},
	rate: {
		get: {
			getCurrentRate: formatRate('')
		},
		post: {
			saveCurrentRate: formatRate('')
		}
	}, 
	email: {
		sendEmail: formatEmail('')
	}
}

export const getStatisticUrl = string => `/statistic${string}`

