import classNames from 'classnames';
import { useEffect } from 'react';
import { addCommas, removeNonNumeric } from '../../lib/utils/string/normalize';
import styles from './Input.module.scss';

const Input = ({
	register,
	name,
	options,
	setValue,
	valueInput,
	error,
	variant = 'form', //form, calculate, range
	className,
	...rest
}) => {
	useEffect(() => {
		if (variant === 'calculate') {
			setValue(name, addCommas(removeNonNumeric(valueInput)))
		}
	}, [valueInput, name, setValue, variant])

	const classes = classNames(styles.input, styles[variant], className)

	return (
		<>
			<input {...register(name, options)} className={classes} {...rest} />
			{error && <div className={styles.error}>{error.message}</div>}
		</>
	)
}

export default Input;
