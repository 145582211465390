import { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import Loader from '../../../../shared/ui/loader/Loader';
import SectionTitle from '../../../../shared/ui/sectionTitle/SectionTitle';
import Stack from '../../../../shared/ui/stack/Stack';
import { initialArray } from '../../lib/data';
import { useStatistic } from '../../lib/hooks/useStatistic';
import styles from './StatisticAdmin.module.scss';
import StatisticAdminItem from './StatisticAdminItem';
import { useTranslatedData } from '../../../../app/translation/lib/useTranslatedData'

const StatisticAdmin = () => {
	const {reset} = useForm({
		mode: 'onChange',
	});

	const data = useTranslatedData();

	const { isLoading, Statistic, error } = useStatistic(reset);
	const statistic = Statistic || initialArray;

	return (
		<Stack direction='column' max>
			<SectionTitle text='Update Statistics' />

			{error && <div className={styles.error}>{data.errorGuide}</div>}

			<form className={styles.form}>
				{statistic.map(item => (
					<Fragment key={item.text}>
						{isLoading ? (
							<Loader />
						) : (
							<StatisticAdminItem
								item={item}
							/>
						)}
					</Fragment>
				))}
			</form>
		</Stack>
	);
};

export default StatisticAdmin;
