import styles from "./MortgageStepCard.module.scss";
import Stack from "../../../../shared/ui/stack/Stack";
import picProcessMortgage from "../../../../shared/assets/images/processStep6.png";
import { useTranslation } from "react-i18next";

const MortgageStepCard = ({ text, number, icon }) => {
  const { i18n } = useTranslation();
  const lang = i18n.language;

  if (number === 6)
    return (
      <Stack className={styles.picProcessMortgage}>
        <img src={picProcessMortgage} alt="mortgage process" loading="lazy" />
      </Stack>
    );

  return (
    <Stack
      className={styles.mortgageStepCard}
      direction="column"
      align="alignCenter"
      justify="justifyStart"
    >
      <Stack
        className={styles.numberMortgageStep}
        direction="row"
        justify="justifyBetween"
        align="alignEnd"
      >
        <img src={icon} alt="a pen" />
        <Stack direction="row" justify="justifyEnd" align="alignEnd">
          <span>{lang === "ru" ? "шаг" : "step"}</span>
          <p>{number} </p>
        </Stack>
      </Stack>
      <Stack className={styles.textMortgageStep} align="alignStart" lang={lang}>
        {text}
      </Stack>
    </Stack>
  );
};

export default MortgageStepCard;
