import processStep1 from "../../../shared/assets/images/processStep1.svg";
import processStep2 from "../../../shared/assets/images/processStep2.svg";
import processStep3 from "../../../shared/assets/images/processStep3.svg";
import processStep4 from "../../../shared/assets/images/processStep4.svg";
import processStep5 from "../../../shared/assets/images/processStep5.svg";

const processIcons = [
  processStep1,
  processStep2,
  processStep3,
  processStep4,
  processStep5,
];

export default processIcons;
