import Button from '../../../../shared/ui/button/Button'
import Stack from '../../../../shared/ui/stack/Stack'
import styles from './Thanks.module.scss'

const Thanks = ({
	isModal,
	onCloseButtonClickSuccess,
	setIsSubmitSuccessful,
	data
}) => {
	const handleClick = () => {
		if (isModal) {
			onCloseButtonClickSuccess()
		} else {
			setIsSubmitSuccessful(false)
			localStorage.setItem('hasGuideSent', 'true')
		}
	}

	return (
		<Stack
			className={styles.thanks}
			direction='column'
		>
			<p>
				{data.thank}
				<br />
				{data.receiveYou}
				<br />
				{data.guideWithinADay}
			</p>
			<Button variant='fill' onClick={handleClick}>
				OK
			</Button>
		</Stack>
	)
}

export default Thanks
