import LazyLoading from '../lazyLoading/LazyLoading'
import styles from './Hr.module.scss'

export const Hr = () => {
	return (
		<LazyLoading
			delay={500}
			triggerOnce={true}
		>
			<hr className={styles.hr} />
		</LazyLoading>
	)
}
