import axios from 'axios';
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getAllReviews = createAsyncThunk('/getAllReviews', async () => {
    try {
        const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/reviews`);
        return data;
    } catch (err) {
        console.log('getAllReviews error redux', err)
    }
});

export const addReview = createAsyncThunk('/addReview', async (review) => {
    try {
        const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/reviews`, review);
        return data;
    } catch (err) {
        console.log('addReview error redux', err);
    }
});

export const deleteReview = createAsyncThunk('/deleteReview', async (id) => {
    try {
        const { data } = await axios.delete(`${process.env.REACT_APP_BASE_URL}/reviews/${id}`);
        return data.images;
    } catch (err) {
        console.log('deleteReview error redux', err);
    }
});

export const reviewsSlice = createSlice({
    name: 'reviews',
    initialState: {
        reviewsArray: [],
        loading: false
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllReviews.pending, ( state ) => {
                state.loading = true;
            })
            .addCase(getAllReviews.fulfilled, ( state, action ) => {
                state.loading = false;
                state.reviewsArray = action.payload?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            })
            .addCase(getAllReviews.rejected, ( state ) => {
                state.loading = false;
            })
            .addCase(addReview.pending, (state) => {
                state.loading = true;
            })
            .addCase(addReview.fulfilled, (state, action) => {
                state.loading = false;
                state.reviewsArray = action.payload?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            })
            .addCase(addReview.rejected, (state) => {
                state.loading = false;
            })
            .addCase(deleteReview.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteReview.fulfilled, (state, action) => {
                state.loading = false;
                state.reviewsArray = action.payload?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            })
            .addCase(deleteReview.rejected, (state) => {
                state.loading = false;
            });
    } 
})

export const getReviewsArray = state => state.reviews.reviewsArray;
export default reviewsSlice.reducer