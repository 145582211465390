import classNames from "classnames";
import Stack from "../../../../shared/ui/stack/Stack";
import styles from "./MortgageStepsBlockMobile.module.scss";
import MortgageStepCardMobile from "../mortgageStepCardMobile/MortgageStepCardMobile";

const MortgageStepsBlockMobile = ({ mortgageSteps }) => {
  return (
    <Stack
      className={styles.mortgageStepsBlockMobile}
      direction="column"
    >
      {mortgageSteps.map((step, i) => (
        <Stack
          key={i}
          className={classNames(
            styles.mortgageStepsMobile,
            styles[step.number]
          )}
        >
          <MortgageStepCardMobile
            text={step.description}
            orientation={i % 2 !== 0 ? "right" : "left"}
          />
        </Stack>
      ))}
    </Stack>
  );
};

export default MortgageStepsBlockMobile;
