import Stack from "../../../../shared/ui/stack/Stack";
import styles from "./TopGetGuide.module.scss";
import { useTranslation } from "react-i18next";

const TopGetGuide = ({ data }) => {
  const { i18n } = useTranslation();

  return (
    <Stack
      className={styles.topGetGuide}
      justify="justifyStart"
      align="alignEnd"
    >
      <Stack className={styles.flag} direction="column">
        <p className={styles.firstRow}>FOR</p>
        <p className={styles.secondRow}>FREE</p>
      </Stack>

      <Stack
        className={styles.slogan}
        direction="column"
        lang={i18n.language}
      >
        <p className={styles.firstRow}>{data.getMortgage}</p>
        <p className={styles.secondRow}>{data.guideBook}</p>
      </Stack>
    </Stack>
  );
};

export default TopGetGuide;
