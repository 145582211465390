const MenuItem = ({ item, itemKey, onClick }) => {

  const handleClick = (e) => {
    e.preventDefault();
    const targetElement = document.getElementById(itemKey);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' })
      requestAnimationFrame(() => {
        onClick();
      });;
    }
    onClick();
  }

  return (
    <li role="menuitem" >
      <a href={`#${itemKey}`} onClick={handleClick}>{item}</a>
    </li>
  )
}

export default MenuItem;