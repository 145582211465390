import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { routes } from './lib/routes.data';
import RefreshPassword from '../../features/auth/ui/authorization/RefreshPassword';

const Router = () => {

	return (
		<BrowserRouter>
			<Routes>
				{routes.map(route => {
					
					return (
						<Route
							key={route.path}
							path={route.path}
							element={<route.component />}
						/>
					)
				})}
				<Route 
					path="/refresh-password/:resetToken" 
					element={<RefreshPassword />} 
				/>
			</Routes>
		</BrowserRouter>
	);
};

export default Router;
