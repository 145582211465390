import Loader from "../../../../shared/ui/loader/Loader";
import Stack from "../../../../shared/ui/stack/Stack";
import { initialArray } from "../../lib/data";
import { useStatistic } from "../../lib/hooks/useStatistic";
import styles from "./Statistic.module.scss";
import StatisticItem from "../staticticItem/StatisticItem";

const Statistic = () => {
	const { isLoading, Statistic } = useStatistic();
	const statistic = Statistic || initialArray;

	return (
		<Stack className={styles.mainBlock}>
			{statistic.map((item) => (
				<Stack
					className={`${styles.itemWrapper} ${isLoading ? styles.loading : ''}`}
					key={item.text}
					align="alignStart"
				>
					{isLoading
						? <Loader />
						: <StatisticItem item={item} />
					}
				</Stack>
			))}
		</Stack>
	);
};

export default Statistic;
