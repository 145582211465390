import React, { createContext, useContext, useState, useEffect } from 'react';
import { getCurrentRate } from '../fetch'; 

const RateContext = createContext();

export const RateProvider = ({ children }) => {
    const [currentRate, setCurrentRate] = useState(5.39);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        getCurrentRate(setCurrentRate, setIsLoading);
    }, []);

    return (
        <RateContext.Provider value={{ currentRate, setCurrentRate, isLoading, setIsLoading }}>
            {children}
        </RateContext.Provider>
    );
};

export const useRate = () => useContext(RateContext);