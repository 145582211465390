import styles from './NavbarItems.module.scss'
import React from 'react';
import { useTranslatedData } from "../../../../app/translation/lib/useTranslatedData"

const NavBarItem = ({ item, onClick }) => {
	const data = useTranslatedData();
	const titleNavbar = data.titleNavbar
	const language = data.language
	const title = item.title;

	let svgIcon;
	if (typeof item.svg === 'object' && item.svg[language]) {
		svgIcon = item.svg[language];
	} else {
		svgIcon = item.svg;
	}

	const handleClick = item.link === '#getGuide' ? undefined : onClick;

	return (
		<a
			href={item.link}
			id={item.link}
			onClick={handleClick}
			className={styles.link}
		>
			{svgIcon}
			<span>{titleNavbar[title]}</span>
		</a>
	)
}

export default NavBarItem
