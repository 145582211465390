import Stack from '../../shared/ui/stack/Stack';
import Button from '../../shared/ui/button/Button';
import styles from './NotFound.module.scss';

const NotFound = () => {
	return (
		<Stack justify='alignCenter' direction="column">
			<div className={ styles.errorContainer }>
				<h1 className={ styles.errorNum }>404</h1>
				<p>Sorry, we were unable to find that page.</p>
				<a href='/'>
					<Button variant="outline">Go back</Button>
				</a>
			</div>
		</Stack>
	);
}

export default NotFound
