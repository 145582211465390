import { useTranslatedData } from '../../../../app/translation/lib/useTranslatedData'
import Button from '../../../../shared/ui/button/Button'
import Stack from '../../../../shared/ui/stack/Stack'
import styles from './QuestionBlock.module.scss'

const QuestionBlock = ({ question, onAnswer }) => {
  const data = useTranslatedData();

  return (
    <Stack
      direction='column'
      className={styles.questionBlockContainer}
    >
      <Stack className={styles.questionText}>
        <p>{question}</p>
      </Stack>
      
      <Stack  className={styles.questionButtons}>
        <Button onClick={() => onAnswer(true)} variant="outline" aria-label='yes'>{data.buttonYes}</Button>
        <Button onClick={() => onAnswer(false)} variant="outline" aria-label='no'>{data.buttonNo}</Button>
      </Stack>
    </Stack>
  );
};

export default QuestionBlock;
