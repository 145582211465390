import styles from "./Contacts.module.scss";
import Stack from "../../../../shared/ui/stack/Stack";
import ContactsBlock from "../contactsBlock/ContactsBlock";
import SectionTitle from "../../../../shared/ui/sectionTitle/SectionTitle";
import { useTranslatedData } from "../../../../app/translation/lib/useTranslatedData";

const Contacts = () => {
  const data = useTranslatedData();
  const d = new Date();
  let year = d.getFullYear();

  return (
    <Stack id="contacts" className={styles.contacts} direction="column" role="contentinfo">
      <SectionTitle text={data.getInTouch} color="secondary" />
      <ContactsBlock />
      <p className={styles.copyright}>
        {`\u00A9 ${year} Rinata Akhmetshina. All rights reserved. \n Created by `}
        <a href="https://www.linkedin.com/company/al-tech-labs-ltd/">AL Tech Labs Ltd.</a>
      </p>
    </Stack>
  );
};

export default Contacts;
