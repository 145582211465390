import { useState } from 'react';
import { useTranslatedData } from '../../../../app/translation/lib/useTranslatedData';
import Stack from '../../../../shared/ui/stack/Stack';
import { useGetGuide } from '../../libs/hooks/useGetGuide';
import Form from '../form/Form';
import MainGetGuide from '../mainGetGuide/MainGetGuide';
import Thanks from '../thanks/Thanks';
import TopGetGuide from '../topGetGuide/TopGetGuide';
import styles from './GetGuide.module.scss';

const GetGuide = ({
	onCloseButtonClick,
	onCloseButtonClickSuccess,
	isModal = false,
}) => {
	const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false)
	const data = useTranslatedData()
	const { formState, handleSubmit, register, isError, submitForm } =
		useGetGuide(setIsSubmitSuccessful)

	return (
		<Stack id="getGuide">
			<Stack
				direction='column'
				justify='justifyEnd'
				align='alignEnd'
				className={styles.wrapper}
			>
				{!isSubmitSuccessful && isModal && (
					<button onClick={onCloseButtonClick} aria-label='close modal'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
						>
							<path
								d='M11.6673 14.3345L21.2132 23.8805L23.3345 21.7591L13.7886 12.2132L23.3345 2.66725L21.2132 0.545929L11.6673 10.0919L2.12133 0.545932L6.06915e-06 2.66725L9.54594 12.2132L0 21.7591L2.12132 23.8805L11.6673 14.3345Z'
								fill='black'
							/>
						</svg>
					</button>
				)}

				<Stack 
					direction='column'
					justify='justifyBetween'
					className={styles.mainContainer}
				>
					<div>
						<TopGetGuide data={data} />
						<MainGetGuide data={data} />
					</div>

					<div>
						{isSubmitSuccessful ? (
							<Thanks
								onCloseButtonClickSuccess={onCloseButtonClickSuccess}
								setIsSubmitSuccessful={setIsSubmitSuccessful}
								isModal={isModal}
								data={data}
							/>
						) : (
							<Form
								data={data}
								handleSubmit={handleSubmit}
								register={register}
								formState={formState}
								submitForm={submitForm}
							/>
						)}

						{isError && <div className={styles.error}>{data.errorGuide}</div>}
					</div>
				</Stack>
			</Stack>
		</Stack>
	)
}

export default GetGuide
