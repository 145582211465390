import { useState, useEffect, useCallback, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Stack from "../../../../shared/ui/stack/Stack";
import styles from "./Calculator.module.scss";
import { PriceContainer } from "../pricesContainer.js/PriceContainer";
import CalculatorBlock from "../calculatorBlock/CalculatorBlock";
import { addCommas } from "../../../../shared/lib/utils/string/normalize";
import { useTranslatedData } from "../../../../app/translation/lib/useTranslatedData";
import SectionTitle from "../../../../shared/ui/sectionTitle/SectionTitle";
import { getCurrentRate } from "../../lib/fetch";
import { useRate } from "../../lib/hook/useRate";
import Loader from "../../../../shared/ui/loader/Loader";

const Calculator = () => {
  const mortgageData = useTranslatedData();
  const Mortgage = mortgageData.mortgageCalculatorData;

  const methods = useForm({
    defaultValues: {
      income: addCommas(0),
      liabilities: addCommas(0),
      duration: 0,
      incomeRange: 0,
      liabilitiesRange: 0,
      durationRange: 0,
    },
    mode: "onChange",
  });

  const { register, setValue } = methods;
  const [maxMonthPayment, setMaxMonthPayment] = useState(0);
  const [maxMortgage, setMaxMortgage] = useState(0);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1440);
  const { currentRate, setCurrentRate, isLoading, setIsLoading } = useRate();
  
  const handleResize = useCallback(() => {
    setIsDesktop(window.innerWidth >= 1440);
  }, []);

  useEffect(() => {
    getCurrentRate(setCurrentRate, setIsLoading);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setCurrentRate, setIsLoading, handleResize]);

  const isSafari = useMemo(() => {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }, []);

  return (
    <Stack id="mortgageCalculator" className={styles.main} direction="column">
      <SectionTitle text={mortgageData.mortgageCalculator} />
      <Stack justify="justifyCenter" className={styles.currentRate}>
        {isLoading ? (
          <Loader width={14} />
        ) : (
          <p>
            {mortgageData.currentRate} {currentRate}%
          </p>
        )}
      </Stack>
      <Stack className={isDesktop ? styles.desktopContent : styles.mobileContent}>
        <FormProvider {...methods}>
          <form className={isSafari ? styles.safariForm : styles.form}>
            {Mortgage.map((item) => (
              <CalculatorBlock
                register={register}
                setValue={setValue}
                key={item.title}
                item={item}
                setMaxMonthPayment={setMaxMonthPayment}
                setMaxMortgage={setMaxMortgage}
              />
            ))}
            <PriceContainer maxMonthPayment={maxMonthPayment} maxMortgage={maxMortgage} />
            {maxMonthPayment <= 0 && (
              <Stack className={styles.error}>
                {mortgageData.reduceOrIncrease}
              </Stack>
            )}
          </form>
        </FormProvider>
      </Stack>
    </Stack>
  );
};

export default Calculator;

