import Calculator from "../../entities/calculator/ui/calculator/Calculator";
import GetGuide from "../../features/get-guide/ui/guide/GetGuide";
import { Hr } from "../../shared/ui/hr/Hr";
import AboutMe from "../../entities/aboutMe/AboutMe";
import Header from "../../widgets/header/ui/header/Header";
import MortgageProcess from "../../entities/mortgageProcess/ui/mortgageProcess/MortgageProcess";
import MyServices from "../../entities/myServices/ui/myServices/MyServices";
import Questionnaire from "../../entities/questionnaire/ui/questionnaire/Questionnaire";
import { Reviews } from "../../entities/reviews/ui/reviews/Reviews";
import Contacts from "../../entities/contacts/ui/contacts/Contacts";
import Navbar from "../../entities/navbar/ui/navbar/Navbar";
import { useModal } from "../../shared/hooks/useModal";
import { useEffect } from "react";
import Modal from "../../shared/ui/modal/Modal";
import useWindowSize from "../../shared/hooks/useWindowSize";
import { Helmet } from 'react-helmet';
import { useTranslatedData } from "../../app/translation/lib/useTranslatedData";
import Statistic from "../../entities/statistic/ui/staisticList/Statistic";

const MainPage = () => {
	const data = useTranslatedData();
	const screenSize = useWindowSize();
	
	const tabletL = 1024;

	const {
		showModal,
		onScrollOpen,
		toggleShowModal,
		toggleShowModalSuccess,
	} = useModal();

	useEffect(() => {
		window.addEventListener('scroll', onScrollOpen)

		return () => {
			window.removeEventListener('scroll', onScrollOpen)
		}
	}, [onScrollOpen]);

	return (
		<main>
			<Helmet>
				<title>
					{data.pageTitle}
				</title>
				<meta name="description" content={data.pageDescription} />
				<meta name="robots" content="index,follow" />
				<link rel="canonical" href="https://ramortgage.cz/" />

				{/* Open Graph Meta Tags */}
				<meta property="og:title" content={data.ogTitle} />
            	<meta property="og:description" content={data.ogDescription} />
            	<meta property="og:image" content={data.language === 'ru' ? 'https://mortage-cz.netlify.app/images/ogImageRu.png' : 'https://mortage-cz.netlify.app/images/ogImageEn.png'} />
            	<meta property="og:url" content="https://ramortgage.cz/" />
            	<meta property="og:type" content="website" />
			</Helmet>

			<Header />
			<AboutMe />
			{(screenSize.width <= tabletL) && <Statistic />}
			{(screenSize.width <= tabletL) && <MyServices />}
			<Reviews />
			<MortgageProcess />
			<Questionnaire />
			<Calculator />
			<Hr />
			<GetGuide />

			<Modal showModal={showModal}>
				<GetGuide
					isModal
					onCloseButtonClick={toggleShowModal}
					onCloseButtonClickSuccess={toggleShowModalSuccess}
				/>
			</Modal>

			<Contacts />
			{(screenSize.width <= tabletL) && <Navbar />}
		</main>
	);
};

export default MainPage;