import { useTranslatedData } from '../../../../app/translation/lib/useTranslatedData'
import { formatNumber } from '../../../../shared/lib/utils/string/normalize'
import Stack from '../../../../shared/ui/stack/Stack'
import styles from './PriceContainer.module.scss'

export const PriceContainer = ({ maxMonthPayment, maxMortgage }) => {

	const data = useTranslatedData();
	return (
		<Stack className={styles.shadowCalculatorContainer}>
			<Stack
				justify='justifyBetween'
				direction='column'
			>
				<span>{data.maxMonthlyPayment}</span>
				<span>{formatNumber(maxMonthPayment)}</span>
				<span>CZK</span>
			</Stack>
			<Stack
				justify='justifyBetween'
				direction='column'
			>
				<span>{data.maxLimit}</span>
				<span>{formatNumber(maxMortgage)}</span>
				<span>CZK</span>
			</Stack>
		</Stack>
	)
}