export const addCommas = num =>
	num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

export const removeCommas = num => parseInt(num.replace(/\D/g, ''))

export const removeNonNumeric = num => (num ? num.toString().replace(/[^0-9]/g, '') : '')


export const formatNumber = number => {
	if (number <= 0) return 0
	return addCommas(number.toFixed())
}

const wordsInRussia = function declOfNum(number, titles) {
	const cases = [2, 0, 1, 1, 1, 2]
	return titles[
		number % 100 > 4 && number % 100 < 20
			? 2
			: cases[number % 10 < 5 ? number % 10 : 5]
	]
}

export 	const getText = (number, name, years, loans, text) => {
	return name !== 'mortgages'
		? `${wordsInRussia(number, years)}${text}`
		: `${text}${wordsInRussia(number, loans)}`
}
